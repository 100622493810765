<template>
  <div class="save-money">
    <div class="save-money-top">
      <div class="money-left">
        <span class="iconfont iconhoutui" @click="goBack"></span>
      </div>
      <div class="save-money-num">
        <span>会员卡号: {{myInfo.card.card_num}}</span>
        <span>用户储值</span>
      </div>
      <div class="save-money-center">
        <span>￥</span>
        <span>{{myInfo.wallet.balance}}</span>
      </div>
    </div>
    <div class="money-item-con" v-if="set_status">
      <div 
        v-for="(item,idx) in moneyArr" 
        :key="idx" 
        @click="setMoney(idx, item)"
        :class="active == idx ? 'active':'money-child'"
      >
        <span>+{{item.charge_money}}元</span>
        <span v-if="item.money_status == 1">送{{item.money}}元</span>
      </div>
    </div>
    <Panel title="赠送内容" style="margin-top:10px" v-if="key>=0">
      <p class="used-wide" v-if="moneyArr[active].point_status == 1">
        <Tag color="#FF3333" mark>积分</Tag>
        {{moneyArr[active].point}}积分
      </p>
      <template v-if="moneyArr[active].coupon_status == 1">
        <p class="used-wide" v-for="(item, idx) in couponsArr" :key="idx">
          <Tag color="#FF3333" mark>优惠券</Tag>
          {{item.title}}
        </p>
      </template>
      
    </Panel>
    <!-- <Panel title="使用说明">
      <div class="used-wide">
        1.初次未开通VIP乐通卡的用户需要点击页面下方的“立即开通”即得
        到一个乐通卡账号，然后根据页面的储值活动进行储值；
        2.在线付款：在乐享靖江APP上面订单提交时选择VIP乐通卡支付，
        相应货款进行抵扣；
        3.线下买单：使用乐享靖江APP进行商家扫码时，选择VIP乐通卡支
      </div>
    </Panel> -->
    <transition name="van-slide-right">
      <div v-show="visible" class="pay-type">
        <div
          class="pay-btn"
          v-for="(item, idx) in paytype"
          :key="idx"
          @click="setPay(item)"
        >
          <!-- <span
            v-if="item.payment_code == 'wxpay'"
            class="iconfont iconweixin"
            style="color:#1AAD19"
          ></span>
          <span
            v-if="item.payment_code == 'cash'"
            class="iconfont iconyue"
            style="color:#FF9933"
          ></span> -->
          <p v-if="item.payment_code == 'unionpay'&&isWeixin_status">微信支付</p>
          <p v-else-if="item.payment_code == 'unionpay'&&!isWeixin_status">支付宝支付</p>
          <p v-else>{{ item.name }}</p>  
        </div>
      </div>
    </transition>
    <!-- <swipe-cell>
      <cell title="开发票" :label="invoiceCont?'向左滑动可取消':''" is-link :value="invoiceCont" @click="openInvoice"/>
      <template slot="right">
        <Button class="cancel-btn" square type="danger" text="取消" @click="cancelInvoice"/>
      </template>
    </swipe-cell> -->
    
    <div class="money-btn">
      <div class="money-pay">
        <span>{{now_money ? '储值' + now_money + '元':'请选择储值金额'}}</span>
        <span class="red_size" @click="visible=!visible">切换支付</span>
      </div>
      <div class="pay-text" @click="goSaveMoney">{{pay_text}}</div>
    </div>
  </div>
</template>
<script>
import { goWxConfigJsApi, wxPayTwo } from "@/utils/wxpay";
import CreditShop from "@/api/creditshop";
import Setting from "@/api/setting";
import { Panel, Tag,  } from 'vant';
import { aliPay } from "@/utils/alipay";
export default {
  components: {
    Panel, Tag,
  },
  data() {
    return {
      active: null,
      key: -1,
      now_money: 0,
      moneyArr: [],
      now_idx: "",
      pay_text: "",
      visible: false,
			myInfo: {
        wallet: {},
        card: {}
      },
      couponsArr: [],
      paytype: [],
      nowpayType: "",
      nowPayIdx: "",
      set_status: false,
      invoiceCont: "选择",
      receipt_id: "",
      recharge_id: "",// 类型id
      return_url: "" //银联回调地址
    }
  },
  async mounted() {
    this.myInfo = await this.getUserInfo();
    this.getSetMoney();
    this.getPayType();
    this.getInvoiceInfor();
  },
  created() {
    // 获取微信支付配置信息
    goWxConfigJsApi("chooseWXPay")
  },
  methods: {
    async getPayType() {
      const pay = ['unionpay', 'wxpay', 'fuiou', 'alipay', 'normal_allinpay'];
      const res = await Setting.getUserPay();
      if (res.code == 200) {
        if (res.data.length > 0) {
          this.paytype = res.data;
          for (let i = 0; i < this.paytype.length; i++) {
            const element = this.paytype[i];
            if(pay.indexOf(element.payment_code)>-1) {
              if(this.isWeixin_status) {
                this.pay_text = '微信支付';
                this.paytype[i].name = '微信支付';
              } else {
                this.pay_text = '支付宝支付';
                this.paytype[i].name = '支付宝支付';
              }
              this.nowpayType = element.payment_code;
              this.payment_code = element.payment_code;
            }
          }
        }
      }
    },
    setPay(item) {
      this.pay_text = item.name;
      this.payment_code = item.payment_code;
      this.visible = false;
      this.nowpayType = item.payment_code;
    },
    async getSetMoney() {
      try {
        const res = await CreditShop.storedInfor(0,this.$route.query.id);
        if (res.code == 200) {
          this.set_status = true; 
          this.moneyArr =  res.data.rule;
          this.recharge_id = res.data.id;
          // this.couponsArr = res.data.rule[0].coupon_data;
        } else {
          this.set_status = false;
          throw(res.msg);
        }
      } catch (error) {
        this.$toast(error);
      }
    },
    setMoney(idx, item) {
      this.active = idx;
      this.key = idx;
      this.now_money = item.charge_money;
      // this.now_idx = item.id;
      if (item.coupon_status == 1) {
        if (item.coupon_data.length > 0) {
          this.couponsArr = item.coupon_data;
        } 
      } else {
        this.couponsArr = [];
      }
      
    },
    backCodeUrl(paysn, ordersn) {
      const user_url = `https://jjtc.58zltc.com`
      const cs_url = `https://www.58zltc.com`
      const callbackUrl = `${user_url}/time_recharge_pay?appid=${localStorage.getItem('app_id')}`+
                    `&app_token=${localStorage.getItem('app_token')}&paysn=${paysn}&ordersn=${ordersn}`;
      const url = `${cs_url}/client/wechat.pay_login/snsapi_base?callback=${encodeURIComponent(callbackUrl)}`;
      return url
    },
    // 支付
    async goSaveMoney() {
      try {
        if(this.payment_code == 'unionpay') {
          this.return_url = `https://jjtc.58zltc.com/vipHome?appid=${localStorage.getItem(
                "app_id"
              )}&`
        } else {
          this.return_url = "";
        }
        if (this.now_money == "") return this.$toast("请选择充值金额"); 
        const res = await CreditShop.addWeChatMoney(this.recharge_id,
          this.nowpayType,
          this.receipt_id,
          0,
          0, 
          this.key, 
          this.return_url,
          this.isWeixin_status ? 'wx' : 'h5',
          sessionStorage.getItem('qrcode_id')
        );
        if (res.code == 200) {
          // 执行微信支付
          switch (this.payment_code) {
            case 'wxpay':
              wxPayTwo(res.data);
              break;
            case 'cash':
              this.$toast(res.msg);
              this.$router.push('/vip');
              break;   
            case 'single_wxpay':
              this.$router.push(`/payewm?imgUrl=${res.data.qrcode}
                &ordersn=${res.data.ordersn}&money=${this.now_money}`);
              break;   
            case 'single_alipay':
              this.$router.push(`/payewm?imgUrl=${res.data.qrcode}
                &ordersn=${res.data.ordersn}&money=${this.now_money}&payType=single_alipay`);
              break;
            case 'unionpay':
              window.location.href = res.data.url;
              break;
            case 'allinpay':
              window.location.href = res.data.url;
              break;
            case 'fuiou':
              if (this.isWeixin_status) {
                if (res.data.pay_page === 1) {
                  const url = this.backCodeUrl(res.data.paysn, res.data.ordersn)
                  window.location.href = url;
                } else {
                  wxPayTwo(res.data);
                }
              } else {
                await aliPay(res.data.targetOrderId);
              }
              break;
            case 'alipay':
              await aliPay(res.data.targetOrderId);
              break;
            case 'normal_allinpay':
              if (this.isWeixin_status) {
                if (res.data.pay_page === 1) {
                  const url = this.backCodeUrl(res.data.paysn, res.data.ordersn)
                  window.location.href = url;
                } else {
                  wxPayTwo(res.data);
                }
              } else {
                await aliPay(res.data.targetOrderId);
              }
              break;
          }
          this.cancelInvoice();
        } else {
          throw(res.msg);
        }
      } catch (error) {
        this.$toast(error);
      }
    },
    openInvoice() {
      this.$router.push({
        path: "/invoice_mag",
        query: {
          source: "order"
        }
      })
    },
    async getInvoiceInfor() {
      try {
        if(this.$store.state.user.invoiceId) {
          const res = await CreditShop.invoiceIdDetail(this.$store.state.user.invoiceId)
          this.receipt_id = res.data.id;
          this.invoiceCont = `发票抬头-${res.data.tax_name}`
        }
      } catch (error) {
        console.log(error)
      }
      
    },
    cancelInvoice() {
      this.receipt_id = "";
      this.invoiceCont = "";
      this.$store.commit("setInvoiceId", "")
    },
  }
}
</script>
<style lang="scss" scoped>
$bgcolor:rgba(244,244,244,1);
.save-money {
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;
  padding-bottom: 50px;
  background: $bgcolor;
  .cancel-btn {
    height: 100%;
  }
  .pay-type {
    width: 120px;
    height: auto;
    position: absolute;
    bottom: 50px;
    right: 0px;
    color: #000000;
    background: #fff;
    z-index: 2;
  }
  .pay-btn {
    width: auto;
    height: 30px;
    text-align: center;
    line-height: 30px;
    display: flex;
    padding: 0 10px;
    border-bottom: 1px solid #dfdfdf;
    justify-content: space-between;
    span {
      font-size: 16px !important;
    }
  }
  .money-item-con {
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    background: #ffffff;
    padding: 10px 20px;
    margin-left: -10px;

    .money-child, .active {
      flex: 1;
      min-width: 36%;
      // height: 50px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      border-radius: 6px;
      padding: 10px 0px;
      margin: 10px;
      display: flex;
      justify-content: space-between;
      background:rgba(244,244,244,1);
      span:nth-child(1) {
        font-size: 16px;
        color: #000000;
      }
      span:nth-child(2) {
        font-size: 12px;
        color: rgba(255,102,0,1);
      }
    }
    .active {
      border: 1px solid rgba(255,102,0,1);
      background: #ffffff;
    }
  }
  .money-left {
    width: 100%;
    height: 40px;
    line-height: 40px;
    text-align: left;
  }
  .save-money-top {
    width: 100%;
    height: 170px;
    font-size: 14px;
    text-align: center;
    color: #ffffff;
    display: flex;
    padding: 0 10px 10px;
    flex-direction: column;
    background-size: cover;
    align-items: center;
    justify-content: space-between;
    background-image: url('../../../public/static/images/home/pay_top.png');
    .save-money-num {
      width: 84%;
      height: 30px;
      display: flex;
      color: #ededed;
      justify-content: space-between;
    }
    .save-money-center {
      margin-bottom: 20px;
      span:nth-child(1) {
        font-size: 14px;
      }
      font-size: 20px;
    }
    .money-record {
      font-size: 12px;
      padding: 2px 10px;
      border: 1px solid rgba(255,255,255,1);
      border-radius: 18px;
    }
  }
  .used-wide {
    width: 100%;
    height: auto;
    line-height: 30px;
    padding-left: 20px;
  }
  .money-btn {
    width: 100%;
    height: 48px;
    display: flex;
    position: absolute;
    bottom: 0px;
    left: 0;
    z-index: 1000;
    color: rgba(140, 140, 140, 1);
    background: #ffffff;
    .pay-text {
      width: 108px;
      border-left: 1px solid #dfdfdf;
      line-height: 48px;
      text-align: center;
      color: #fff;
      padding: 0 10px;
      background: #1AAD19;
    }
    .money-pay {
      flex: 1;
      height: 100%;
      display: flex;
      padding: 0 10px;
      align-items: center;
      justify-content: space-between;
     .red_size{
        color:rgba(255,102,0,1);
        font-size: 16px !important;
      }
    }
  }
}
</style>